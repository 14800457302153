// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 👇 Axios Imports
import axios from 'axios'
import { urlAuthService, urlEquipmentService, urlReportingService } from '../../../../endpoints'
import { getUriParams } from '@utils'

const initialState = {
    loaded:false,
    statsData: {},
    data: [],
    total: 0
}

export const getStuffingReportStats = async (companyCode) => {
    const customerDashboardDTO = {
        fromDate: new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString(), // 6 months ago
        toDate: new Date().toISOString(),
        customerCode: companyCode
    }
    const response = await axios.post(`${urlReportingService}/GetStuffingReportStats`, customerDashboardDTO)
    return {
        data: response.data
    }
}

export const getReportList = createAsyncThunk('customerDashboard/getReportList', async (data) => {
    const { params, filters } = data
    try {
    const uri = getUriParams(params)
    const response = await axios.post(`${urlReportingService}/GetAllTransloadBookingReportList?${uri}`, filters)
    return {
        response: response.data.data,
        totalPages: response.data.totalCount
    }
  } catch (error) {
    console.log(error)
  }
})

export const getScheduleTerminalData = createAsyncThunk('customerDashboard/getScheduleTerminalData', async (data) => {
    const { params, filters } = data

    const uri = getUriParams(params)
    const filterData = {
        movementType: "schedule", // Customer Dashboard only to show scheduled movement
        yardLocation: filters.terminal || "ALL",
        customerName: filters.customerName === "ALL" || filters.customerName === "" ? "" : filters.customerName || "",
        line: filters.line || "",
        referenceNumber: filters.referenceNumber || "",
        equipmentStatus: filters.equipmentStatus || "",
        equipmentNumber: filters.equipmentNumber || "",
        commodityType: filters.commodityType || "",
        equipmentType: filters.equipmentType || "ALL",
        from: filters.from || null,
        to: filters.to || null,
        destination: filters.destination || "",
        origin: filters.origin || "",
        cargoType: filters.cargoType || "",
        commodity: filters.commodity || "",
        grade: filters.grade || "",
        lotNumber: filters.lotNumber || "",
        lastTracedLocation: filters.lastTracedLocation || "",
        servingYard: filters.servingYard || "",
        tracingStatus: filters.tracingStatus || "",
        equipmentSize: filters.equipmentSize || "",
        ...(filters.tracingStatus !== 'TRANSIT' ? { [filters.tracingStatus]: filters.tracingDate } : {})
        }
        const response = await axios.post(`${urlEquipmentService}/GetEquipmentByMovementType?${uri}`, filterData)
    return {
        params,
        data: response.data.data,
        totalPages: response.data.totalCount,
        filterData
    }
})

export const getSpecialReport = createAsyncThunk('customerDashboard/getSpecialReport', async (customerCode) => {
    const response = await axios.get(`${urlReportingService}/GetSpecialReport/${customerCode}`)
    return {
        data: response.data.data,
        totalCount: response.data.totalCount
    }
})

export const getCTCIntactReport = createAsyncThunk('customerDashboard/getCTCIntactReport', async (billingPeriod) => {
    const response = await axios.get(`${urlReportingService}/GetCTCIntactReport/${billingPeriod}`)
    return response.data
})

export const getCTCTransloadReport = createAsyncThunk('customerDashboard/getCTCTransloadReport', async ({vesselName, voyageNumber}) => {
    const response = await axios.get(`${urlReportingService}/GetCTCTransloadReport/${vesselName}/${voyageNumber}`)
    return response.data
})

export const getCTCLiftStorageReport = createAsyncThunk('customerDashboard/getCTCLiftStorageReport', async (billingPeriod) => {
    const response = await axios.get(`${urlReportingService}/GetCTCLiftStorageReport/${billingPeriod}`)
    return response.data
})

export const getNovaTransloadReport = createAsyncThunk('specialCustomer/getSpecialReportByPONumber', async (PONumber) => {
    const response = await axios.get(`${urlReportingService}/GetNovaTransloadBookingReport/${PONumber}`)
    return response.data
})

// JTW Token
export const getIsValidToken = async () => {
    const response = await axios.get(`${urlAuthService}/IsValidToken`)
    return response.success
}

export const putRefreshToken = async () => {
    const response = await axios.get(`${urlAuthService}/RefreshToken`)
    return response.success
}


export const customerDashboardSlice = createSlice({
    name: 'customerDashboard',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getReportList.fulfilled, (state, action) => {
            state.dataReport = action.payload.response
            state.totalReport = action.payload.totalPages
            state.loaded = true
        })
        .addCase(getScheduleTerminalData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
            state.filter = action.payload.filterData
            state.loading = false
        })
        .addCase(getSpecialReport.fulfilled, (state, action) => {
            state.specialReportData = action.payload.data
            state.specialReportTotal = action.payload.totalCount
        })
        .addCase(getCTCIntactReport.fulfilled, (state, action) => {
            state.ctcIntact = action.payload
        })
        .addCase(getCTCTransloadReport.fulfilled, (state, action) => {
            state.ctcTransload = action.payload
        })
        .addCase(getCTCLiftStorageReport.fulfilled, (state, action) => {
            state.ctcLiftStorage = action.payload
        })
        .addCase(getNovaTransloadReport.fulfilled, (state, action) => {
            state.novaTransload = action.payload
        })
        //if loading, set loaded to false
        builder.addMatcher(
            action => {
                return action.type.includes('/pending')
            },
            (state) => {
                state.loaded = false
            }
        )
    }
})

export default customerDashboardSlice.reducer