// 👇 Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
// 👇️ Endpoints
import { urlTransloadingRateService } from '@src/endpoints'
// 👇️ Utils
//import { getUriParams } from '@utils'

import toast from 'react-hot-toast'
// 👇 Components
import { ToastContent } from "@src/views/components/toast/ToastContent"

const ToastMessage = (heading, message, isSuccess) => {
    toast(t => (
        <ToastContent t={t} heading={heading} response={isSuccess} message={message} />
    ))
}
const getParamString = (params) => {
    let string = ""
    if (Object.keys(params).length > 0) {
        Object.keys(params).map((key) => {
            if (!!params[key]) {
                string += `${key}=${params[key]}&`
            }
        })
    }
    return string
}
//transloading rate list
export const getTransloadingRates = createAsyncThunk(
    "transloadingRate/getTransloadingRates",
    async (data) => {
        const { params, filters } = data
        const response = await axios.post(
            `${urlTransloadingRateService}/GetAllTransloadingRate?${getParamString(params)}`, filters)
        return {
            data: response.data.data, // this is an array of objects
            totalCount: response.data.totalCount, // this is a direct property of the response
            params,
            filterData: filters
        }
    }
)
// get transloading rate by id
export const getTransloadRateById = createAsyncThunk('transloadingRate/getTransloadRateById ', async id => {
    const response = await axios.get(`${urlTransloadingRateService}/GetTransloadingRate/${id}`)
    return {
        selectedRate: response?.data?.data,
        loadedRate: true
    }
})

//add transloading rate
export const addTransloadingRate = createAsyncThunk(
    "transloadingRate/addTransloadingRate",
    async (data, { dispatch }) => {
        const response = await axios.post(
            `${urlTransloadingRateService}/AddTransloadingRate`,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
        if (response.status === 200) {
            await dispatch(getTransloadingRates({ params: {}, filters: {} }))
            ToastMessage('Transloading Rate', response?.data?.messages, true)
            return true
        } else {
            ToastMessage('Transloading Rate', response?.data?.errors, false)
            return false
        }
    }
)
//deactivate transloading rate
export const deactivateTransloadingRate = createAsyncThunk(
    "transloadingRate/deactivateTransloadingRate",
    async ({ id, note }, { dispatch }) => {
        const response = await axios.put(
            `${urlTransloadingRateService}/DeactivateTransloadingRate`,
            {
                id,
                note
            },
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
        if (response.status === 200) {
            await dispatch(getTransloadRateById(id))
            ToastMessage('Transloading Rate', response?.data.messages, true)
            return true
        } else {
            ToastMessage('Transloading Rate', response?.errors, false)
            return false
        }
    }
)
//delete transloading rate
export const deleteTransloadingRate = createAsyncThunk(
    "transloadingRate/deleteTransloadingRate",
    async (id, { dispatch }) => {
        const response = await axios.delete(
            `${urlTransloadingRateService}/DeleteTransloadingRate/${id}`
        )
        if (response.status === 200) {
            await dispatch(getTransloadingRates({ params: {}, filters: {} }))
            ToastMessage('Transloading Rate', response?.data.messages, true)
            return true
        } else {
            ToastMessage('Transloading Rate', response?.data.errors, false)
            return false
        }
    }
)

// update transloading rate
export const updateTransloadingRate = createAsyncThunk(
    "transloadingRate/updateTransloadingRate",
    async (data, { dispatch }) => {
        const response = await axios.put(
            `${urlTransloadingRateService}/UpdateTransloadingRate`,
            data,
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
        if (response.status === 200) {
            await dispatch(getTransloadRateById(data.id))
            ToastMessage('Transloading Rate', response?.data.messages, true)
            return true
        } else {
            ToastMessage('Transloading Rate', response?.data.errors, false)
            return false
        }
    }
)

export const transloadingRateSlice = createSlice({
    name: 'transloadingRate',
    initialState: {
        data: [],
        params: {},
        total: 0,
        filter: {},
        loading: false,
        selectedRate: null

    },
    name: 'transloadingRate',

    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getTransloadingRates.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalCount
                state.filter = action.payload.filterData
            })
            .addCase(getTransloadingRates.rejected, (state) => {
                state.loading = false
                state.data = []
                state.params = {}
                state.total = 0
                state.filter = {}
            })
            .addCase(getTransloadRateById.fulfilled, (state, action) => {
                state.selectedRate = action.payload.selectedRate
                state.loadedRate = action.payload.loadedRate
            })
    }
})

export default transloadingRateSlice.reducer