import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// 👇 Axios Imports
import axios from 'axios'
import { urlShipSmplService, urlFreightForwardService } from '@src/endpoints' //, urlIdentityService, urlConfigCacheService, urlOceanBookingService
import { getUriParams } from '@src/utility/Utils'
import toast from 'react-hot-toast'
// 👇 Components
import { ToastContent } from "@src/views/components/toast/ToastContent"

const ToastMessage = (heading, message, isSuccess) => {
  toast(t => (
    <ToastContent t={t} heading = {heading} response = {isSuccess} message = {message} />
  ))
}
// ** Get all Data
export const getAllData = createAsyncThunk('ffRequests/getAllData', async () => {
  const response = await axios.get(`${urlFreightForwardService}/GetFFStatusCount`)
  return {
    data: response.data,
    loadedAll: true
  }
})
export const getData = createAsyncThunk('ffRequests/getData', async params => {
  const uri = getUriParams(params)
  const FFRequestFilter = {
    id: "",
    ffNumber: params.ffNumber === '' ? null : params.ffNumber,

    shipperRefNumber: params.shipperRefNumber === '' ? null : params.shipperRefNumber,
    oceanBookingNumber: params.oceanBookingNumber === '' ? null : params.oceanBookingNumber,
    companyCode: params.companyCode?.value || null,
    //customer: params.customer?.label || null,
    containerSizeId: params.containerSizeId?.value,
    fromDate: params.fromDate === '' ? null : params.fromDate,
    toDate: params.toDate === '' ? null : params.toDate,
    destination: params.destination?.label,
    customer: params.customer?.label,
    status: params.ffStatusId?.value
  }
  const response = await axios.post(`${urlFreightForwardService}/GetAllFFRequest?${uri}`, FFRequestFilter)
  return {
    params,
    data: response.data,
    totalPages: response.data.totalCount,
    loaded: true
  }
})

export const getFFRequest = createAsyncThunk('ffRequests/getFFRequest', async id => {
  const response = await axios.get(`${urlFreightForwardService}/GetFFRequest/${id}`)
  return {
    selectedCustomer: response.data.data[0],
    ffRequest: response.data.data[0],
    status: response.data.data[0].status,
    oceanBookings: response.data.data[0].oceanBookings,
    landBookings: response.data.data[0].landBookings,
    ffNotes: response.data.data[0].ffNotes,
    selectedCompany: response.data.data[0].companyName,
    loadedFFR: true
  }
})

export const getOceanBookingList = createAsyncThunk('ffRequests/getOceanBookingList', async (OceanBookingIdModel) => {
  const response = await axios.post(`${urlShipSmplService}/GetOceanBookingList`, OceanBookingIdModel)
  return {
    oceanBookingList: response.data,
    loadedOBL: true
  }
})

//add FFRequest
export const addFFRequest = createAsyncThunk('ffRequests/addFFRequest', async (FFRequestViewModel, { dispatch, getState }) => {
  const response = await axios.post(`${urlFreightForwardService}/AddFFRequest`, FFRequestViewModel)
  
  await dispatch(getData(getState().freightForward.params))
  await dispatch(getAllData())
  //await dispatch(getFFResponse(response))
  return {
    ffResponse: response
  }
})
export const updateFFRequest = createAsyncThunk('ffRequests/updateFFRequest', async (FFRequestUpdateModel, { dispatch }) => {
  await axios.put(`${urlFreightForwardService}/UpdateFFRequest`, FFRequestUpdateModel)
  await dispatch(getData(getState().freightForward.params))
  await dispatch(getAllData())
  await dispatch(getFFRequest(FFRequestUpdateModel.id))
  await dispatch(getData(FFRequestUpdateModel.id))
  return FFRequestUpdateModel
})
//delete FFRequest
export const deleteFFRequest = createAsyncThunk('ffRequests/deleteFFRequest', async ({ id }, { dispatch, getState }) => {
  const response = await axios.delete(`${urlFreightForwardService}/DeleteFFRequest/${id}`)
  response.data.success === true ? ToastMessage('Promotion', response.data.messages[0], true) : ToastMessage('Promotion', response.data.errors[0], false)
  await dispatch(getData(getState().freightForward.params))

  return id
})


export const addNotes = createAsyncThunk('ffRequests/addNotes', async (FFNotesViewModel, { dispatch }) => {
  await axios.post(`${urlFreightForwardService}/AddFFNotes`, FFNotesViewModel)
  await dispatch(getFFRequest(FFNotesViewModel.FFRequestId))
  return FFNotesViewModel
})

export const updateStatus = createAsyncThunk('ffRequests/updateStatus', async (FFStatusUpdateModel, { dispatch }) => {
  await axios.post(`${urlFreightForwardService}/UpdateFFStatus`, FFStatusUpdateModel)
  await dispatch(getFFRequest(FFStatusUpdateModel.id))
  return FFStatusUpdateModel
})


//assign FF Ocean Booking
export const assignFFOceanBooking = createAsyncThunk(
  'ffRequests/assignFFOceanBooking',
  async ({ ffRequest, isPrimary }, { dispatch, getState }) => {
    const url = `${urlFreightForwardService}/AssignFFOceanBooking`

    const oceanData = ffRequest.oceanBookings
    /* eslint-disable no-unused-vars */
    const { oceanbookingId, id, ...restOceanData } = oceanData
    
    // Create new oceanBookingData object with oceanBookingId set to id
    const newOceanData = {
      ...restOceanData,
      oceanBookingId: id
    }
    const postData = {
      ffNumber: ffRequest.FFNumber || ffRequest.ffNumber || ffRequest.ffRequest.FFNumber,
      oceanBookings: newOceanData // Always include the current ocean bookings
    }

    if (isPrimary) {
      console.log("Primary dispatched")
      console.log("isPrimary:", isPrimary)
    } else {
      console.log("isPrimary:", isPrimary)
      // Ensuring that backupOceanBookings is an array and correctly appending data.
      postData.backupOceanBookings = [...(ffRequest.backupOceanBookings || []), { ...ffRequest.oceanBookings, bookingNumber: `Backup-${ffRequest.oceanBookings.bookingNumber}` }]
      console.log("Backup dispatched")
    }

    try {
      const response = await axios.post(url, postData)
      console.log("API response:", response.data)
    } catch (error) {
      console.error("Failed to dispatch API call:", error)
    }

    await dispatch(getData(getState().freightForward.params))
    await dispatch(getAllData())
    return ffRequest // Return the ffRequest for any further processing
  }
)

//unassign FF Ocean Booking
export const unAssignFFOceanBooking = async (FFOceanBookingDeleteModel) => {
    const response = await axios.post(`${urlFreightForwardService}/UnAssignFFOceanBooking`, FFOceanBookingDeleteModel)
    response?.data?.success === true ? ToastMessage('Promotion', response?.data?.messages, true) : ToastMessage('Promotion', response?.data?.errors, false)
  if (FFOceanBookingDeleteModel.count === 0) {
    const FFStatusUpdateModel = {
      FFRequestId: FFOceanBookingDeleteModel.FFRequestId,
      StatusId: 1,
      notes: 'Change status to Initial Request!',
      noteType: true
    }
    axios.post(`${urlFreightForwardService}/UpdateFFStatus`, FFStatusUpdateModel)
  }
  return FFOceanBookingDeleteModel
}

//assign FF Land Booking
export const assignFFLandBooking = createAsyncThunk(
  'ffRequests/assignFFLandBooking',
  async (ffRequest, { dispatch, getState }) => {
    try {
      // Directly using ffRequest to build the request payload
      const response = await axios.post(`${urlFreightForwardService}/AssignFFLandBooking`, {
        ffNumber: ffRequest.ffRequest.FFNumber || ffRequest.ffNumber,
        landBookings: ffRequest.ffRequest.landBookings
      })
      const { freightForward } = getState() // Accessing part of the Redux state
      await dispatch(getData(freightForward.params))
      await dispatch(getAllData())

    } catch (error) {
      console.error("Failed to dispatch API call:", error)// Logging errors from the API call
    }
    return ffRequest// Returning ffRequest for further processing or indication of completion
  }
)

//unassign FF Land Booking
export const unAssignFFLandBooking = async (FFLandBookingDeleteModel) => {

  const response = await axios.post(`${urlFreightForwardService}/UnAssignFFLandBooking`, FFLandBookingDeleteModel)
  response?.data?.success === true ? ToastMessage('Success', response?.data?.messages, true) : ToastMessage('Error', response?.data?.errors, false)
  if (FFLandBookingDeleteModel.count === 0) {
    const FFStatusUpdateModel = {
      FFRequestId: FFLandBookingDeleteModel.FFRequestId,
      StatusId: 1,
      notes: 'Change status to Initial Request!',
      noteType: true
    }
    axios.post(`${urlFreightForwardService}/UpdateFFStatus`, FFStatusUpdateModel)
  }
  return FFLandBookingDeleteModel
}

// ** Get all Status
export const getAllStatus = createAsyncThunk('ffRequests/getAllStatus', async () => {
  const response = await axios.get('/api/freight-forward/status/all-status')
  return {
    data: response.data
  }
})

// ** Get all Status
export const getAllContainerSize = createAsyncThunk('ffRequests/getAllContainerSize', async () => {
  const response = await axios.get('/api/freight-forward/containerSize/all-size')
  return {
    data: response.data
  }
})

export const ffRequestsSlice = createSlice({
  name: 'freightForward',
  initialState: {
    loaded: false,
    loadedAll: false,
    loadedOBL: false,
    loadedFFR: false,
    data: [],
    total: 1,
    params: {},
    allData: {},
    selectedCustomer: null,
    ffRequest: null,
    ffResponse: null,
    status: [],
    oceanBookings: [],
    landBookings: [],
    oceanBookingList: [],
    ffNotes: [],
    selectedCompany: '',
    oceanBookingLists: {},
    allStatus: [],
    allContainerSize: []

  },
  reducers: {
     },
  extraReducers: builder => {
    builder
      .addCase(getAllData.pending, (state) => {
        state.loadedAll = false
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.loadedAll = action.payload.loadedAll
        state.allData = action.payload.data
      })
      .addCase(getData.pending, (state) => {
        state.loaded = false
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.loaded = action.payload.loaded
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.oceanBookingLists = action.payload.oceanBookingLists
      })
      .addCase(addFFRequest.fulfilled, (state, action) => {
        state.ffResponse = action.payload.ffResponse
      })
      .addCase(getFFRequest.pending, (state) => {
        state.loadedFFR = false
      })
      .addCase(getFFRequest.fulfilled, (state, action) => {
        state.selectedCustomer = action.payload.selectedCustomer
        state.ffRequest = action.payload.ffRequest
        state.status = action.payload.status
        state.oceanBookings = action.payload.oceanBookings
        state.landBookings = action.payload.landBookings
        state.ffNotes = action.payload.ffNotes
        state.selectedCompany = action.payload.selectedCompany
        state.loadedFFR = action.payload.loadedFFR
      })
      .addCase(getOceanBookingList.pending, (state) => {
        state.loadedOBL = false
      })
      .addCase(getOceanBookingList.fulfilled, (state, action) => {
        state.loadedOBL = action.payload.loadedOBL
        state.oceanBookingList = action.payload.oceanBookingList
      })
      .addCase(getAllStatus.fulfilled, (state, action) => {
        state.allStatus = action.payload.data
      })
      .addCase(getAllContainerSize.fulfilled, (state, action) => {
        state.allContainerSize = action.payload.data
      })
  }
})
export default ffRequestsSlice.reducer
ffRequestsSlice.metadata = {
  name: 'Zakeer Sainalabdeen',
  github: 'zakeerwtc',
  description: 'FF Orders - Store {Get Data from Microservices}',
  filename: 'index',
  githubFile: 'https://github.com/WTCGroup/Frontend/blob/main/src/views/apps/freight-forward-request/store/index.js',
  prodUrl: ''
}