import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { urlMiscellaneousEquipmentService } from "@src/endpoints"

const getParamString = (params) => {
  let string = ""
  if (Object.keys(params).length > 0) {
    Object.keys(params).map((key) => {
      if (!!params[key]) {
        string += `${key}=${params[key]}&`
      }
    })
  }
  return string
}

export const fetchAllMiscellaneousEquipmentData = createAsyncThunk(
  "MiscellaneousEquipment/fetchAllMiscellaneousEquipment",
  async (data, { rejectWithValue }) => {
    const { params, filters } = data
    try {
      const response = await axios.post(
        `${urlMiscellaneousEquipmentService}/GetAllMiscellaneousEquipment?${getParamString(data.params)}`, 
        data.filters || {}
      )
      return {
        params,
        filterData: filters,
        data: response.data.data || [],
        totalCount: response.data.totalCount || 1
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue({
          status: error.response.status,
          messages: error.response.data.Messages
        })
      } else {
        return rejectWithValue({ Messages: ['An unexpected server error occurred.'] })
      }
    }
  }
)

export const getMiscellaneousEquipmentData = createAsyncThunk(
  "MiscellaneousEquipment/getMiscellaneousEquipmentData",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${urlMiscellaneousEquipmentService}/GetMiscellaneousEquipment/${id}`
      )
      return response.data
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue({
          status: error.response.status,
          messages: error.response.data.Messages
        })
      } else {
        return rejectWithValue({ Messages: ['An unexpected server error occurred.'] })
      }
    }
  }
)

export const addMiscellaneousEquipmentData = createAsyncThunk(
  "MiscellaneousEquipment/addMiscellaneousEquipmentData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${urlMiscellaneousEquipmentService}/AddMiscellaneousEquipment`,
        data
      )
      return response.data
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue({
          status: error.response.status,
          messages: error.response.data.Messages
        })
      } else {
        return rejectWithValue({ Messages: ['An unexpected server error occurred.'] })
      }
    }
  }
)

export const updateMiscellaneousEquipmentData = createAsyncThunk(
  "MiscellaneousEquipment/updateMiscellaneousEquipmentData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${urlMiscellaneousEquipmentService}/UpdateMiscellaneousEquipment`,
        data
      )
      return response.data
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue({
          status: error.response.status,
          messages: error.response.data.Messages
        })
      } else {
        return rejectWithValue({ Messages: ['An unexpected server error occurred.'] })
      }
    }
  }
)

export const deleteMiscellaneousEquipmentData = createAsyncThunk(
  "MiscellaneousEquipment/deleteMiscellaneousEquipmentData",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${urlMiscellaneousEquipmentService}/deleteMiscellaneousEquipment/${data.id}`
      )
      return response.data
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue({
          status: error.response.status,
          messages: error.response.data.Messages
        })
      } else {
        return rejectWithValue({ Messages: ['An unexpected server error occurred.'] })
      }
    }
  }
)

export const miscellaneousEquipmentSlice = createSlice({
  name: "MiscellaneousEquipment",
  initialState: {
    miscellaneousEquipmentData: [], 
    totalCount: 0,
    loaded: true,
    loading: false,
    error: null,
    filter: {
      timeIn: null,
      TimeOut: null,
      Company: ""
    },
    params: {
      sort: "",
      sortColumn: "TimeIn",
      q: '',
      page: 1,
      perPage: 10,
      status: ""
    },
    filterForm: {} 
    
  },
  reducers: {
    setFilterForm: (state, action) => {       
      state.filterForm = action.payload      
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMiscellaneousEquipmentData.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAllMiscellaneousEquipmentData.fulfilled, (state, action) => {
        state.miscellaneousEquipmentData = action.payload || []
        state.miscellaneousEquipmentData.params = action.payload.params
        state.params = action.payload.params
        state.totalCount = action.payload.totalCount
        state.loading = false
      })
      .addCase(fetchAllMiscellaneousEquipmentData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(getMiscellaneousEquipmentData.pending, (state) => {
        state.loading = true
      })
      .addCase(getMiscellaneousEquipmentData.fulfilled, (state, action) => {
        state.loading = false
        state.miscellaneousEquipmentData = action.payload
      })
      .addCase(getMiscellaneousEquipmentData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(addMiscellaneousEquipmentData.pending, (state) => {
        state.loading = true
      })
      .addCase(addMiscellaneousEquipmentData.fulfilled, (state, action) => {
        state.loading = false
        state.successMessage = action.payload
      })
      .addCase(addMiscellaneousEquipmentData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
        state.error = action.payload.Messages
      })
      .addCase(updateMiscellaneousEquipmentData.pending, (state) => {
        state.loading = true
        state.error = null 
      })
      .addCase(updateMiscellaneousEquipmentData.fulfilled, (state, action) => {
        state.loading = false
        state.successMessage = action.payload.messages[0]
        state.error = null 
      })
      .addCase(updateMiscellaneousEquipmentData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
        state.successMessage = null
      })
      .addCase(deleteMiscellaneousEquipmentData.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteMiscellaneousEquipmentData.fulfilled, (state, action) => {
        state.loading = false
        state.successMessage = action.payload
      })
      .addCase(deleteMiscellaneousEquipmentData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  }
})

export const { setFilterForm } = miscellaneousEquipmentSlice.actions

export default miscellaneousEquipmentSlice.reducer
